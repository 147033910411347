<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.container-pane
  position: fixed
  // bottom: 0px
  // right: 0px
  // width: calc(100vw - 370px - 0px)
  // height: 50vh
  // overflow: scroll
  // border-color: #555 !important

  top: 0px
  right: 0px
  z-index: 1
  height: 100vh
  width: calc(50vw)
  min-width: 300px


  .th
    vertical-align: middle
.item-history
  border-left: solid 2px #fff
  // font-size: 14px
  font-weight: 500
  // &.active
  //   border-left: solid 2px #007bff
  // &:hover
  //   border-left: solid 2px #007bff
  //   background-color: #fafafa
  //   cursor: pointer
  &:hover
    border-left: solid 2px #007bff
    background-color: #fafafa
    cursor: pointer
    .link-open
      opacity: 0.3
  &.active
    border-left: solid 2px #007bff
    // background-color: #fafafa
    background-color: #fff
  .link-open
    opacity: 0
.text-fail
  // font-weight: bold
  color: mix(tomato, #555, 80%)
.detail-body
  cursor: initial
.message-type-SMS
  color: #f0f0f0 !important
  background-color: #3498db !important
  padding: 2px 3px
.message-type-EMAIL
  color: #f0f0f0 !important
  background-color: #1abc9c !important
  padding: 2px 3px
.btn-tag-close
  &:hover
    background-color: rgba(0,0,0,.0)
    svg
      background-color: rgba(0,0,0,.05)
      border-radius: 50%
</style>
<template lang="pug">
//- div.container-pane.shadow.bg-white.border
  //- .container-search-pad
//- div
  modal(name='record' width='500px' :height='`auto`' :scrollable='true')
    .p-4
      h5 예약완료문자
        span.text-muted.ml-2 체크리스트
div.bg-white(style='min-height: 500px')
  //- (style='margin-top: -61px; position: relative; z-index: 1')
  //- div.container-search.bg-white.shadow-sm.pl-2
    router-link(:to='`/property/${property_id}/activity`')
      span.text-dark(style='font-size: 18px; line-height: 50px')
        b-icon-chevron-left
        | 뒤로
    strong.text-secondary.ml-4.mr-2 {{document.name}}
  div(@keyup.esc.stop='$modal.hide("tag")')
    modal.modal-name-tag.rounded(name='tag' transition='tag' :shiftY='0.2' :height='`auto`' :scrollable='true' @keyup.esc.stop='close_tag' @keyup.enter.stop='' @before-close='before_close_tag')
      //- button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("tag")' style='font-size: 1.5rem')
        b-icon-x
      //- div.title.py-3.pl-3 태그 선택
      //- search-tag(:tags='tags' @updated='tag_did_updated' :defaultSelected='current_tag')
  div.p-3.text-center.bg-light(style='color: #888;' v-show='row.deleted_at')
    h5 삭제된 내역입니다.
  form(@submit.prevent='submit' @keyup.esc.stop='')
    table.table(v-if='row.id')
      tbody
        tr(v-show='!hide_customer')
          th.bg-light.w-25.text-right ID
            span.d-block

            span.d-inline.float-right
              dropdown(:active='dropdown_id_active')
                template(slot='toggler')
                  button.btn.btn-default.btn-sm(type='button' style='font-size: 12px; padding: .25rem .5rem; color: #999' @click='load_group()')
                    | 아이디 조회
                    b-icon-link45deg
                dropdown-content
                  .text-center.p-2(v-show='!dropdown_id_loaded')
                    b-spinner(label='검색 중...' small variant='secondary')
                  //- dropdown-block.mt-1
                    input.form-control.form-control-sm.form-dropdown(type='text' value='목록 이름')
                  div(v-show='dropdown_id_loaded')
                    //- a.link-dropdown.text-left.p-2.d-block(href='#' @click.prevent='update_external_id(1)') @고객아이디
                    a.link-dropdown.text-left.p-2.d-block(href='#' @click.prevent='open_group_record(row)' v-for='row in group_rows' v-if='row.document.id != document_id')
                      span.d-block {{row.document.name}}
                      small.text-muted.mr-1(v-for='(col) in row.document.config.cols' v-if='col.position==`primary`') {{row.json[col.key]}}
                      //- small.text-muted.mr-1(v-for='(col, $index) in row.document.config.cols' v-if='col.position==`primary`') {{row.json[col.key] || ($index == 1 ? '-' : '')}}
                  //- hr.my-2
          td
            input.form-control(type='text' v-model='row.external_id' readonly autofocus)

            //- dropdown(:active='dropdown_id_active')
              template(slot='toggler')
                button.btn.mt-2.mr-2.py-2.btn-secondary.rounded-pill.shadow-sm(type='button' @click='load_group()')
                  | {{group_record_document && group_record_document.name || '아이디 조회'}}
                  b-icon-link45deg.ml-1
              dropdown-content
                .text-center.p-2(v-show='!dropdown_id_loaded')
                  b-spinner(label='검색 중...' small variant='secondary')
                //- dropdown-block.mt-1
                  input.form-control.form-control-sm.form-dropdown(type='text' value='목록 이름')
                div(v-show='dropdown_id_loaded')
                  //- a.link-dropdown.text-left.p-2.d-block(href='#' @click.prevent='update_external_id(1)') @고객아이디
                  a.link-dropdown.text-left.p-2.d-block(href='#' @click.prevent='open_group_record(row)' v-for='row in group_rows' v-if='row.document.id != document_id')
                    span.d-block {{row.document.name}}
                    small.text-muted.mr-1(v-for='(col) in row.document.config.cols' v-if='col.position==`primary`') {{row.json[col.key]}}

            //- div.mt-2(v-if='group_record_id')
              .shadow.mt-2(style='border: solid 2px #ccc')
                customer-record(
                  :property_id='property_id'
                  :document='group_record_document'
                  :document_id='group_record_document.id'
                  :record_id='group_record_id'
                  :hide_customer='true'
                )
            div.mt-2(v-if='group_record_id')
              router-link.btn.mr-2.py-2.btn-secondary.rounded-pill.shadow-sm(:to='`/property/${property_id}/customer/${group_record_document.id}/${$options.filters.encodeText(group_record_document.name)}/${group_record_id}`' target='_blank')
                | {{group_record_document.name}}
                //- small.ml-2: b-icon-box-arrow-up-right
              //- .shadow.mt-2.border
              .shadow.mt-2(style='border: solid 2px #ccc')
                customer-record(
                  :property_id='property_id'
                  :document='group_record_document'
                  :document_id='group_record_document.id'
                  :record_id='group_record_id'
                  :hide_customer='true'
                )
        tr(v-for='(col, $index) in document.config.cols' v-show='(!hide_customer || col.format[0] != `@`)')
          th.bg-light.w-25.text-right(:class='{"border-0":(hide_customer)}') {{col.label}}

          td(:class='{"border-0":(hide_customer)}')
            template(v-if='col.format == `text`')
              input.form-control(type='text' v-model='row.json[col.key]' :disabled='row.deleted_at')
              p.text-muted.mb-0.text-left(style='font-weight: normal; white-space: pre') {{col.description}}
            template(v-else-if='col.format == `check`')
              b-form-checkbox.rounded-0(v-model='row.json[col.key]' value='Y' unchecked-value='N' :disabled='row.deleted_at') {{col.description}}
            template(v-else-if='col.format == `textarea`')
              textarea.form-control(v-model='row.json[col.key]' rows=10 :disabled='row.deleted_at')
              p.text-muted.mb-0.text-left(style='font-weight: normal; white-space: pre') {{col.description}}
            template(v-else)
              input.form-control(type='text' v-model='row.json[col.key]' :disabled='row.deleted_at')

        tr
          th.bg-light.w-25.text-right(:class='{"border-0":(hide_customer)}') 태그
          td
            transition-group(type='transition' name='tag')
              .btn-group.mr-1.mb-1(v-for='tag in row_tags' :key='tag.id')
                button.btn.btn-light.px-2.py-1(type='button' @click='select_tag(tag)') {{tag.name}}
                button.btn.btn-light.px-1.py-1(style='border-left: 0 !important'): b-icon-x(style='color: #ccc' @click.prevent.stop='remove_tag(tag)')
            //- button.btn.btn-primary.px-2.py-1.shadow-sm.mr-1.mb-1.float-right(type='button' @click='search_tag()')
            .text-right
              button.btn.btn-primary.px-2.py-1.shadow-sm.mr-1.mb-1(type='button' @click='search_tag()' :disabled='row.deleted_at')
                //- small.mr-1: b-icon-search
                | 태그검색 추가하기
            .clearfix
            transition-group(type='transition' name='tag')
              div.shadow-lg.rounded.pb-2(:key='show_tag_search' v-if='show_tag_search')
                //- style='position: absolute; right: 40px; background-color: #fff; width: 500px'
                button.btn.btn-default.float-right.text-muted.rounded-0.btn-tag-close(type='button' @click='search_tag' style='font-size: 1.5rem; position: relative; top: 100px; margin-top: -100px;')
                  b-icon-x
                form(@submit.prevent.stop='')
                  search-tag(:tags='tags' @updated='tag_did_updated' :defaultSelected='current_tag')

        tr
          th.bg-light.w-25.text-right(:class='{"border-0":(hide_customer)}') 발송내역
            br
            span.text-muted {{message_record_rows.length}} 건
          td.p-0
            .p-2(v-show='!message_record_loaded')
              //- button.btn.mr-2.font-weight-bold.py-2.btn-secondary.rounded-pill.shadow-sm(type='button') 전체
              //- button.btn.mr-2.py-2.btn-light.rounded-pill.shadow-sm(type='button') 메시지
              //- br
              //- span 2건 (총 2건)
              .text-center.p-2
                b-spinner(label='가져오는 중...' small variant='secondary')
            div.async.pb-4(ref='message_history' :class='{done:message_record_loaded}' style='height: 18rem; overflow: scroll')
              //- pre {{$store.state.users_by_id}}
              .item-history.p-2.text-muted(v-for='row in message_record_rows' @click.prevent='row.opened = !row.opened' :class='{active:(row.opened)}')
                strong.text-dark.mr-1
                  span(v-if='row.message && row.message.user_id') {{ $store.state.users_by_id[row.message.user_id].name}}
                  span(v-else) 이름없음
                span.text-muted.mr-2
                  a(:href='`/property/${property_id}/message/${row.message_id}/audience`' target='_blank') {{row.message.title}}
                span.mr-2(v-if='row.type == "EMAIL" && row.result_json' style='width: 15rem; text-overflow: ellipsis; word-break: break-all; display: inline-block; overflow: hidden; white-space: nowrap; height: 15px;') {{row.result_json.email_subject_result}}
                span.mr-2(v-if='row.type == "SMS" && row.result_json' style='width: 15rem; text-overflow: ellipsis; word-break: break-all; display: inline-block; overflow: hidden; white-space: nowrap; height: 15px;') {{row.result_json.sms_body_result}}
                span.text-muted.float-right {{ row.created_at | fromNow }}
                br
                span.text-muted.mr-2(:class='["message-type-"+row.type]') {{row.type}}
                span.mr-2(:class='{"text-fail": (row.status_text=="발송실패")}') {{row.status_text}}
                //- a(v-if="ENV == 'development'" href='#' @click.prevent.stop='history_action("retry", row)') test retry

                span.mr-2(v-if='row.status_text == "발송실패"')
                  button.btn.btn-default.btn-sm.p-0(type='button' @click.prevent='history_action("retry", row)' :disabled='row.deleted_at') 재시도
                span {{row.error}}
                div.detail-body(v-if='row.type == "SMS"')
                  a.text-muted.float-right.mr-2.link-open(href='#' @click.prevent.stop='row.opened = !row.opened' v-if='row.result_json')
                    strong.mr-1(v-if='row.opened') 상세 문자정보 접기
                    strong.mr-1(v-else) 상세 문자정보 보기
                    b-icon-chevron-up(v-if='row.opened')
                    b-icon-chevron-down(v-else)
                  br
                  div.pt-2.pl-2(@click.stop='' v-show='row.opened' v-if='row.result_json')
                    dl.clearfix.mb-0
                      dt.float-left 보낸분
                      dd.float-right.mb-0(style='color:#888') {{row.result_json.sms_number_from}}
                    dl.clearfix.mb-0
                      dt.float-left 받는분
                      dd.float-right.mb-0(style='color:#888') {{row.result_json.sms_number_to}}
                    dl.clearfix.mb-0
                      dt.float-left 내용
                      dd.float-right.mb-0.bg-light.p-2(style='color:#555; font-size: 16px; white-space: pre-line; width: 15rem') {{row.result_json.sms_body_result}}
                div.detail-body(v-else-if='row.type == "EMAIL"')
                  a.text-muted.float-right.mr-2.link-open(href='#' @click.prevent.stop='row.opened = !row.opened' v-if='row.result_json')
                    strong.mr-1(v-if='row.opened') 이메일 접기
                    strong.mr-1(v-else) 이메일 보기
                    b-icon-chevron-up(v-if='row.opened')
                    b-icon-chevron-down(v-else)
                  br
                  //- pre {{row.result_json}}
                  div.pt-2.pl-2(@click.stop='' v-show='row.opened' v-if='row.result_json')
                    dl.clearfix.mb-0
                      dt.float-left.d-inline-block(style='width: 50px') 보낸분
                      dd.float-left.mb-0(style='color:#888') {{row.result_json.email_from}}
                        span(v-if='row.result_json.email_from_name') ({{row.result_json.email_from_name}})
                    dl.clearfix.mb-0
                      dt.float-left.d-inline-block(style='width: 50px') 제목
                      dd.float-left.mb-0(style='color:#888') {{row.result_json.email_subject_result}}
                    dl.clearfix.mb-0
                      dt.float-left.d-inline-block(style='width: 50px') 받는분
                      dd.float-left.mb-0(style='color:#888') {{row.result_json.email_to}}
                    dl.clearfix.mb-0
                      dt.float-left 내용
                      dd.float-right
                        a(:href='`${API_URL}/v1/DM/${row.id}/${row.uuid}`' target='_blank' @click.stop='') 웹페이지 열기
                    .mb-0.bg-light.p-2(style='color:#555; font-size: 16px; white-space: pre-line; word-break: break-all') {{row.result_json.email_body_result.slice(0, 100)}}


                //- strong.float-right
                  span.mr-2(v-if='row.state == "발송실패"') 발송실패
                  a(:href='`/property/${property_id}/message/${row.message_id}/audience`' target='_blank') 조회
        //- tr
          th.bg-light.w-25.text-right(:class='{"border-0":(hide_customer)}') 내역
          td.p-0
            .p-2
              button.btn.mr-2.font-weight-bold.py-2.btn-secondary.rounded-pill.shadow-sm(type='button') 전체
              button.btn.mr-2.py-2.btn-light.rounded-pill.shadow-sm(type='button') 메시지
              br
              //- span 2건 (총 2건)
              .text-center.p-2(v-show='!history_loaded')
                b-spinner(label='가져오는 중...' small variant='secondary')
            div(v-show='history_loaded')
              //- pre {{$store.state.users_by_id}}
              .item-history.p-2(v-for='row in history_rows')
                span(v-if='row.name == "document_record.message.created"')
                  strong.text-dark.mr-1 {{ $store.state.users_by_id[row.user_id].name}}
                  span.text-muted.mr-2 목록 메시지보내기 요청
                  span.text-muted {{ row.created_at | fromNow }}
                  strong.float-right
                    span.mr-2(v-if='row.state == "발송실패"') 발송실패
                    a(:href='`/property/${property_id}/message/${row.message_id}/audience`' target='_blank') 조회
                span(v-else-if='row.name == "document_record.message_record.requested"')
                  strong.text-dark.mr-1 {{ $store.state.users_by_id[row.user_id].name}}
                  span.text-muted.pl-3.pr-1: b-icon-arrow-return-right
                  span.text-muted.mr-2 발신
                  span.text-muted {{ row.created_at | fromNow }}
                  strong.float-right(v-if='row.state == "발송실패"')
                    a(href='#' @click.prevent='history_action("retry", row)') {{row.state}}
                  strong.float-right(v-else) {{row.state}}
                span(v-else)
                  strong.text-dark.mr-1 {{ $store.state.users_by_id[row.user_id].name}}
                  span.text-muted.mr-2 {{ row.name }}
                  span.text-muted {{ row.created_at | fromNow }}
                  strong.float-right(v-if='row.state == "발송실패"')
                    a(href='#' @click.prevent='history_action("retry", row)') {{row.state}}
                  strong.float-right(v-else) {{row.state}}


        tr
          th
          td
            button.btn.btn-primary(type='submit' :disabled='saving || row.deleted_at') {{saving_text}}
            button.btn.btn-default.text-danger.float-right(type='button' @click='remove' v-show='!row.deleted_at') 삭제
            button.btn.btn-default.text-danger.float-right(type='button' @click='remove_undo' v-show='row.deleted_at') 삭제 복구


</template>

<script>

import Dropdown from "@/components/Dropdown";
import DropdownContent from "@/components/DropdownContent";
import DropdownItem from "@/components/DropdownItem";
import DropdownBlock from "@/components/DropdownBlock";

import SearchTag from "@/components/SearchTag";

export default {
  name: 'customer-record',
  props: ['property_id', 'document', 'tags', 'document_id', 'record_id', 'record_row_id', 'hide_customer'],
  components: {
    Dropdown, DropdownContent, DropdownItem, DropdownBlock,
    SearchTag,
  },
  computed: {

  },
  watch: {
    // '$store.state.documents'() {
    //   this.load()
    // },
    // 'document_id'() {
    //   this.load()
    // },
    'record_row_id'() {
      console.log('rid updated')
      this.load()
    },
  },
  data() {
    return {
      ENV: process.env.NODE_ENV,
      API_URL: process.env.VUE_APP_BASE_API_URL,
      done: false,
      saving: false,
      saving_text: '저장',
      // document: {},
      row: {},

      dropdown_id_active: false,
      dropdown_id_loaded: false,

      group_rows: [],
      group_record_id: null,
      group_record_document: {},

      message_record_rows: [],
      message_record_loaded: false,

      row_tags: [],
      current_tag: null,
      show_tag_search: false,
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    console.log(this.record_row_id)
    if (this.property_id && this.document && this.record_row_id) {
      this.load()
    }
  },
  methods: {
    close_tag() {
      console.log(1)
    },
    before_close_tag() {
      // 전체 태그 업데이트
      setTimeout(() => {
        this.$emit('tag_updated')
      }, 1000)
    },
    select_tag(tag) {
      if (this.current_tag && this.current_tag.id == tag.id) {
        this.show_tag_search = false
        return
      }

      this.current_tag = tag
      // this.$modal.show('tag')
      this.show_tag_search = false
      setTimeout(() => {
        this.show_tag_search = true
      }, 300);
    },
    async remove_tag(tag, silent = false) {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      if(!silent && !confirm(`[${tag.name}] 태그를 삭제할까요?`)) return false
      const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${this.row.id}/tags/actions/remove`, {
        name: tag.name,
      })
      if (r2?.data?.message != 'ok') throw new Error(r2?.data?.message || '태그 저장실패')
      this.load_tags()
      this.$emit('tag_updated')

      // setTimeout(() => {
      //   this.$emit('tag_updated')
      // }, 1000)
    },
    search_tag() {
      // this.$modal.show('tag')
      this.show_tag_search = !this.show_tag_search
    },
    async tag_did_updated(tag) {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      console.log('tag>', tag)
      try {
        const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${this.row.id}/tags/actions/add`, {
          name: tag.name,
        })
        if (r2?.data?.message != 'ok') throw new Error(r2?.data?.message || '태그 저장실패')

        if (this.current_tag) {
          // await this.remove_tag(this.current_tag, true)
          this.current_tag = null
        }
        this.load_tags()
        this.$emit('tag_updated')
        // this.$modal.hide('tag')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }

    },
    async history_action(action, row) {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      if (action == 'retry') {
        if(!confirm('발송실패한 메시지만 재발송 요청 가능합니다. 진행할까요?')) return false
        try {
          const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document.id}/messages/${row.message_id}/records/${row.id}/actions/retry`)
          if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '실패')
          setTimeout(() => {
            this.load_message_record()
          }, 300);
          this.$modal.show('dialog', {
            title: '알림',
            text: '요청했습니다.'
          })
        } catch (error) {
          this.$modal.show('dialog', {
            title: '알림',
            text: error.message,
          })
        }
      }
    },
    open_group_record(row) {
      this.group_record_document = row.document
      this.group_record_id = row.external_id

      // close
      this.dropdown_id_active = Date.now()
    },
    async load_group() {
      console.log('>>>>>>>>> loadgroup')
      if (this.dropdown_id_loaded) return

      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/group-records/${decodeURIComponent(this.record_id)}`, {

        })
        if (r?.data?.message != 'ok') throw new Error('고객 데이터 가져오기 실패')
        this.group_rows = r.data.rows.map(e => {
          e.document = this.$store.state.documents_by_id[e.document_id]
          e.json = JSON.parse(e.json)
          return e
        })

        setTimeout(() => {
          this.dropdown_id_loaded = true
        }, 300);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    update_external_id() {
      console.log(11)
      this.dropdown_id_active = Date.now()
    },
    async load_tags() {
      const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${this.row.id}/tags`)
      if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '고객 태그 가져오기 실패')
      this.row_tags = r.data.rows

      // this.search_tag()
    },
    async load() {
      // this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      // if (!this.document.id) {
      //   throw new Error('해당 목록을 찾지 못했습니다.')
      // }
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/row/${this.record_row_id}`, {

        })
        // if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '고객 데이터 가져오기 실패')
        this.row = r.data.row

        await this.load_tags()

        this.done = true
        this.$emit('loaded')

        this.load_message_record()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async load_message_record() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/record-ids/${this.row.id}/message-records`, {

        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '내역 가져오기 실패')
        this.message_record_rows = r.data.rows.map(e => {
          e.opened = false
          return e
        })

        this.message_record_loaded = true
        setTimeout(() => {
          console.log(this.$refs.message_history)
          const items = this.$refs.message_history.querySelectorAll('.item-history')
          if (items.length > 3) {
            items[items.length-1].scrollIntoView({block: 'nearest'})
          }
        }, 100);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit() {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      this.saving = true
      this.saving_text = '저장 중'
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/row/${this.record_row_id}`, {
          row: this.row.json,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)

        const external_id = r.data.external_id

        // if (this.row.external_id != r.data.external_id) {
        //   if (!this.hide_customer) {
        //     this.$router.push({
        //       path: `/property/${this.property_id}/customer/${this.document_id}/${this.$options.filters.encodeText(this.document.name)}/${r.data.id}/${encodeURIComponent(r.data.external_id)}`,
        //     })
        //   }
        // } else {
        //   this.load()
        // }
        this.load()

        this.saving = false
        this.saving_text = '저장됨'

        // if (!this.hide_customer) {
        //   this.$emit('updated')
        // }
        this.$emit('updated', {
          external_id,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
    async remove() {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      try {
        if(!confirm('삭제하시겠습니까?')) return false

        const r = await this.$http.delete(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/row/${this.record_row_id}`)
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)
        // this.load()

        this.$emit('updated')
        // this.$router.push({
        //   path: `/property/${this.property_id}/customer/${this.document_id}/${this.$options.filters.encodeText(this.document.name)}`,
        // })
        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
        this.load()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
    async remove_undo() {
      if (!this.row.deleted_at) {
        console.log('cannot recover undeleted row')
        return
      }
      try {
        if(!confirm('삭제된 내역을 복구 하시겠습니까?')) return false

        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/row/${this.record_row_id}/actions/undo_delete`)
        if (r?.data?.message != 'ok') throw new Error('복구 실패. ' + r.data.message)
        // this.load()

        this.$emit('updated')
        // this.$router.push({
        //   path: `/property/${this.property_id}/customer/${this.document_id}/${this.$options.filters.encodeText(this.document.name)}`,
        // })
        this.$modal.show('dialog', {
          title: '알림',
          text: '복구했습니다.',
        })
        this.load()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
  },
}
</script>
