<style lang="sass" scoped>
.scroll
  height: 300px
  // min-height: 300px
  overflow: scroll
  // max-height: 300px
.item
  border-left: solid 2px #fff
  font-size: 14px
  font-weight: 500
  &.active
    border-left: solid 2px #007bff
  // &:hover
  //   border-left: solid 2px #007bff
  //   background-color: #fafafa
  //   cursor: pointer
  &.selected
    border-left: solid 2px #007bff
    background-color: #fafafa
.query
  font-size: 18px
  padding: 2rem 1rem
</style>
<template lang="pug">
div
  input.form-control.query(
    type='text' v-model='q' ref='input'
    :placeholder='placeholder'
    @input='q = $event.target.value'
    @keydown.down.prevent='select_down()'
    @keydown.up.prevent='select_up()'
    @keyup.enter.prevent='select_enter()'
    @keyup='do_filter'
  )
  .scroll.py-2(ref='items')
    //- pre {{selected}}
    .item.px-3.py-2(v-for='(item, i) in filtered_items' :key='item.id' @click='select(item)' :class="{selected:(selected_index===i)}" @mouseover='selected_index = i')
      span(v-if='!item.id')
        span.mr-2(style='opacity: 0.6') "{{item.name}}"
        span 태그 생성하고 추가
      span(v-else) {{item.name}}
        //- span.float-right.text-muted(v-if='item.description') {{item.description}}
</template>

<script>
import * as hangul from 'hangul-js'
import {findIndex} from 'lodash'

export default {
  name: "SearchFilter",
  props: ['document', 'tags', 'default-selected'],
  data() {
    return {
      q: '',
      placeholder: '태그 이름을 검색합니다.',
      items: [],
      filtered_items: [],

      selected: {},
      selected_index: 0,
    }
  },
  mounted() {
    if (this.defaultSelected) {
      this.selected = this.defaultSelected
      this.q = this.defaultSelected.name

      this.load()
      this.do_filter()
      if (this.selected) {
        const idx = findIndex(this.filtered_items, (o) => { return o.id == this.selected.id })
        console.log({idx}, this.filtered_items, this.selected.id)
        if (idx) this.selected_index = idx
      }
    } else {
      this.load()
    }
  },
  methods: {
    select(item) {
      item.ts = Date.now()
      console.log('select ', item)

      this.$emit('updated', item)
      return
    },
    select_down() {
      this.selected_index = Math.min(this.filtered_items.length-1, this.selected_index+1)

      const items = this.$refs.items.querySelectorAll('.item')
      if (items[this.selected_index]) {
        items[this.selected_index].scrollIntoView({block: 'nearest'})
      }
    },
    select_up() {
      this.selected_index = Math.max(0, this.selected_index-1)
      const items = this.$refs.items.querySelectorAll('.item')
      if (items[this.selected_index]) {
        items[this.selected_index].scrollIntoView({block: 'nearest'})
      }
    },
    select_enter() {
      if (this.filtered_items.length === 1) {
        return this.select(this.filtered_items[0])
      }
      const item = this.filtered_items[this.selected_index]
      if (item) {
        this.select(item)
      }
    },
    do_filter() {
      let q = String(this.q).trim()
      if (q.length === 0) {
        this.filtered_items = this.items
        return
      }

      let matched_exact = false
      this.filtered_items = [].concat(this.items).filter(e => {
        let matched_name = false
        if (q.length >= 1) {
          const v = hangul.search(e.name, q)
          if (v >= 0) {
            matched_name = true
            if (q == e.name) {
              matched_exact = true
            }
          }
        }
        // if (q.length >= 2) {
        //   matched_email = e.email.replace(/\.|@/g, '').match(new RegExp(q, 'im'))
        // }
        // if (q.length >= 1) {
        //   matched_name = e.name.replace(/\s/g, '').match(new RegExp(q, 'im'))
        // }
        return matched_name
      })
      if (this.filtered_items.length === 0 || !matched_exact) {
        this.filtered_items.push({
          id: null,
          name: q,
        })
      }
      console.log('>>>>>', this.filtered_items.length)
    },
    load() {
      setTimeout(() => {
        this.$refs.input?.focus()
      }, 300);


      this.items = this.tags
      this.filtered_items = this.items
    }
  },
};
</script>
